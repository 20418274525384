import { css } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import {
  localizeAmountAndRecurrence,
  type PolicyDeductibleDescription,
  type PolicyOptionalGuarantee,
} from '@orus.eu/dimensions'
import { Avatar, Button, Markdown, colors, primaryColor, secondaryColor, useLanguage } from '@orus.eu/pharaoh'
import { Fragment, memo, useCallback, useMemo } from 'react'

import { amountToString, newAmount, type Amount } from '@orus.eu/amount'
import { InfoDialog, type InfoDialogSection } from '@orus.eu/pharaoh/src/components/features/quote-v3'
import type { Option } from '@orus.eu/pharaoh/src/components/features/quote-v3/types'
import { Row, RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { translate, type Language, type LocalizableMessage } from '@orus.eu/translations'
import { useNavigateBack } from '../../../lib/hooks/use-navigate-back'
import { useLargeScreen } from '../../../lib/use-large-screen'
import { useWindowLocationHash } from '../../../lib/use-window-location-hash'
import { computeDeductibleToDisplay } from '../../pages/subscription/utils'
import { optionalGuaranteeImages } from './optional-guarantee-images'

type OptionalGuaranteeListProps = {
  optionalGuarantees: PolicyOptionalGuarantee[]
}

const rceEmployeur = {
  description: {
    description: `Nous vous protégeons lorsque vous causez un dommage à un de vos préposés (employé, stagiaire, sous-traitant...).

Les risques suivants sont notamment couverts :

* Faute inexcusable causant un accident du travail ou une maladie professionnelle
* Faute intentionnelle d’un de vos préposés sur un autre de vos préposés
* Objets personnels de vos préposés
* Participation à des salons et évènements
* Intoxication alimentaire.`,
    example: `Vous n’avez pas fait installer de rampe dans l’escalier menant à votre bureau. Un de vos employés tombe dans l’escalier et se casse une jambe.

Il s’agit d’une faute inexcusable.

➡️ Grâce à l’option RC Employeur, nous prenons en charge l’indemnisation de votre employé et le remboursement à la Sécurité Sociale. En cas de procédure pénale, vos frais de défense sont également remboursés.`,
  },
}

export const OptionalGuaranteeList = memo<OptionalGuaranteeListProps>(function OptionalGuaranteeList(props) {
  const { optionalGuarantees } = props

  const navigateBack = useNavigateBack()

  const onClick = useCallback((guarantee: PolicyOptionalGuarantee) => {
    window.location.hash = `option-${guarantee.type}`
  }, [])

  const elements: { rows: JSX.Element[]; modals: JSX.Element[] } = useMemo(() => {
    const rows: JSX.Element[] = []
    const modals: JSX.Element[] = []

    optionalGuarantees.map((guarantee) => {
      const guaranteeType = guarantee.type
      rows.push(
        <Row
          key={guarantee.type}
          onClick={() => onClick(guarantee)}
          title={guarantee.name}
          leftAvatar={<Avatar variant="contained" src={optionalGuaranteeImages[guaranteeType]} size="50" />}
          rightAvatar={<Avatar icon="angle-right-solid" size="30" />}
        />,
      )

      modals.push(
        <OptionalGuaranteeModalDisplayer key={guarantee.type} optionalGuarantee={guarantee} onClose={navigateBack} />,
      )
    })

    return { rows, modals }
  }, [navigateBack, onClick, optionalGuarantees])

  return (
    <>
      <div>
        {elements['modals']}
        <RowContainer size="medium" variant="simple">
          {elements['rows']}
        </RowContainer>
      </div>
    </>
  )
})

/**
 * This wrapper around the legacy modal control the modal visibility.
 * @deprecated Use useDialogVisibility in combination with OptionalGuaranteeDialog
 */
export const OptionalGuaranteeModalDisplayer = memo<{
  optionalGuarantee: PolicyOptionalGuarantee
  onClose: () => void
  addButton?: {
    monthlyPrice: Amount
    handleSubmit: (option: Option, type: 'add' | 'remove') => void
  }
  quotePageType?: 'static' | 'dynamic'
}>(function OptionalGuaranteeModalDisplayer(props) {
  const { optionalGuarantee } = props
  const hash = useWindowLocationHash()
  const showModal = hash === `option-${optionalGuarantee.type}`
  return showModal ? <OptionalGuaranteeDialog {...props} /> : <></>
})

type OptionalGuaranteeDialogProps = {
  optionalGuarantee: PolicyOptionalGuarantee
  onClose: () => void
  addButton?: {
    monthlyPrice: Amount
    handleSubmit: (option: Option, type: 'add' | 'remove') => void
  }
  quotePageType?: 'static' | 'dynamic'
}
export const OptionalGuaranteeDialog = memo<OptionalGuaranteeDialogProps>(function OptionalGuaranteeDialog(props) {
  const language = useLanguage()
  const { optionalGuarantee, addButton, onClose, quotePageType } = props
  const isLargeScreen = useLargeScreen()
  const modalType = quotePageType ?? 'dynamic'
  const addOptionText = `Ajouter cette option (${amountToString(addButton?.monthlyPrice ?? newAmount('1'), {
    displayDecimals: true,
    addCurrency: true,
  })}/mois)`

  const addOption = useCallback(() => {
    addButton?.handleSubmit(
      {
        image: optionalGuaranteeImages[optionalGuarantee.type],
        type: optionalGuarantee.type,
        name: optionalGuarantee.name,
        shortText: optionalGuarantee.shortText,
        price: optionalGuarantee.price ? localizeAmountAndRecurrence(optionalGuarantee.price, language) : undefined,
        selected: optionalGuarantee.isActive,
        recommended: optionalGuarantee.isRecommanded,
        deductible: computeDeductibleToDisplay(optionalGuarantee.deductibles),
        ceiling: {
          amount: optionalGuarantee.guaranteeLimit.limits[0].limit ?? 'Aucun',
          locked: optionalGuarantee.guaranteeLimit.limits[0].isGuaranteeFixed,
        },
      },
      'add',
    )
    window.location.hash = ''
  }, [addButton, language, optionalGuarantee])

  const sections = useMemo<InfoDialogSection[]>(() => {
    if (optionalGuarantee.children?.length) {
      return [
        {
          type: 'subguarantees',
          // The name is not used when there's only one section
          name: '',
          description: <Markdown markdown={optionalGuarantee.fullText} />,
          coverage: [
            {
              options: optionalGuarantee.children.map((child) => ({
                icon: optionalGuaranteeImages[child.type],
                description: child.name,
                ceiling: child.guaranteeLimit.limits[0].limit ?? 'Aucun',
                deductible: computeDeductibleToDisplay(child.deductibles),
              })),
            },
          ],
        },
      ]
    }

    const baseSection = {
      // The name is not used when there's only one section
      type: 'info' as const,
      name: 'Exploitation',
      description: <Markdown markdown={optionalGuarantee.fullText} />,
      info: [
        {
          title: translateLocalisableName(optionalGuarantee.guaranteeLimit, language),
          content: (
            <>
              {optionalGuarantee.guaranteeLimit.limits.map((limit, key, array) => (
                <Fragment key={key}>
                  <span
                    css={css`
                      color: ${colors.blue[500]};
                    `}
                  >
                    {limit.limit ?? 'Aucun'}
                  </span>

                  {` ${limit.description ?? ''}`}
                  {key !== array.length - 1 ? <br /> : <></>}
                </Fragment>
              ))}
            </>
          ),
        },
        {
          title: translateLocalisableName(optionalGuarantee.deductibles, language),
          content: (
            <>
              {optionalGuarantee.deductibles.descriptions.map((desc, key, array) => (
                <Fragment key={key}>
                  {desc.deductible ? (
                    desc.deductible
                  ) : (
                    <span
                      css={css`
                        color: ${colors.blue[500]};
                      `}
                    >
                      Aucune
                    </span>
                  )}

                  {desc.description ? ` ${desc.description}` : undefined}
                  {key !== array.length - 1 ? <br /> : <></>}
                </Fragment>
              ))}
            </>
          ),
        },
      ],
      example: <Markdown markdown={optionalGuarantee.example} />,
    }

    return [
      baseSection,
      ...(optionalGuarantee.type === 'rce'
        ? [
            {
              ...baseSection,
              name: 'Employeur',
              description: <Markdown markdown={rceEmployeur.description.description} />,
              example: <Markdown markdown={rceEmployeur.description.example} />,
            },
          ]
        : []),
    ]
  }, [language, optionalGuarantee])

  return (
    <InfoDialog
      variant={isLargeScreen ? 'desktop' : 'mobile'}
      title={optionalGuarantee.name}
      image={optionalGuaranteeImages[optionalGuarantee.type]}
      sections={sections}
      onClose={onClose}
      recommended={optionalGuarantee.isRecommanded}
      button={
        addButton && modalType === 'dynamic' ? (
          <Button variant="primary" size={isLargeScreen ? 'medium' : 'large'} onClick={addOption}>
            {addOptionText}
          </Button>
        ) : undefined
      }
    />
  )
})

function translateLocalisableName(
  value: { name: string } | { localizableName: LocalizableMessage },
  language: Language,
) {
  if ('name' in value) {
    return value.name
  }
  return translate(value.localizableName, language)
}

type OptionalGuaranteeDeductibleProps = {
  deductiblesDescription: PolicyDeductibleDescription[]
}

const OptionalGuaranteeDeductible: React.FunctionComponent<OptionalGuaranteeDeductibleProps> = memo((props) => {
  const { deductiblesDescription } = props
  return (
    <Box>
      {deductiblesDescription.map((desc, key) => (
        <Box
          key={key}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body2" sx={{ color: colors.gray[700] }}>
            <Box component="span" sx={{ color: secondaryColor }}>
              {desc.deductible ?? 'Aucune'}
            </Box>
            {desc.description ? (
              <Box component="span" sx={{ color: primaryColor }}>
                {` ${desc.description}`}
              </Box>
            ) : undefined}
          </Typography>
          <br />
        </Box>
      ))}
    </Box>
  )
})

OptionalGuaranteeDeductible.displayName = 'OptionalGuaranteeDeductible'
